.e-input-group input.e-input,
.e-input-group.e-control-wrapper input.e-input {
    font-size: 20px;
    height: 40px;
}

.datetime {
    display: block;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    border-radius: 5px;
    
    appearance: none;
    border-radius: .375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.datetime .react-datetime-picker{
    padding: 5px;
    border: 1px solid #ced4da;
}

.fw-700{
    font-weight: 700 !important;
}