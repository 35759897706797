body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn-primary {
  color: #fff !important;
  background-color: #d50000 !important;
  border-color: #d50000 !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: #d50000 !important;
  border-color: #d50000 !important;
}

.btn-info {
  color: #fff !important;
  background-color: #505050 !important;
  border-color: #505050 !important;
}

.btn-info:hover {
  color: #fff !important;
  background-color: #505050 !important;
  border-color: #505050 !important;
}

.br-50 {
  border-radius: 50px !important;
  width: 40px;
  height: 40px;
}
