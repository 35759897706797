.form-wrapper{
    margin: 20px;
    text-align: left;
}

.assign_search{
    display: flex;
    align-items: center;
    justify-content: right;
    color: #E0E0E0;
}
.assign_search .searchbar {
    height: 40px;
    background-color: #FFF;
    padding: 0 20px;
    border-radius: 50px;
    border: 1px solid #EEEEEE;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.assign_search .searchbar input {
    margin-left: 15px;
    height: 38px;
    width: 100%;
    border: none;
    font-family: 'Montserrat', sans-serif;
}