.rdt_TableHeader .hcgwbo {
    color: black;
    font-size: 30px;
    margin-left: 5px;
}

.dkTRUaX {
    margin-left: 5px;
}

.rdt_TableCol {
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    color: #1c1b1b;
}


.mr-3 {
    margin-right: 0.4rem;
}

.rdt_TableCell div {
    font-size: 16px;
}

/* .rdt_TableRow,
.rdt_TableHeadRow {
    background-color: rgb(245, 245, 245) !important;
} */

.rdt_TableCell {
    margin-left: 15px;
}

.badgeactive {
    line-height: 1.5;
    border-radius: 1.03125rem;
    padding: 0.25rem 0.625rem;
    border: 0.0625rem solid transparent;
}
.model-manager{
    margin: 20px;
    padding: 50px;
    border: 2px solid #899499;
    border-radius: 40px;
}
.modal-title{
    padding-left: 20px;
}