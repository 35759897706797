.bg-img {
  background: url("../images/login-truck.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.w-100-p {
  width: 100%;
}

#section-01:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  /* background: linear-gradient(white, #f0e68c, #800000);  */
  background: linear-gradient(#ffffff7d, #f0e68c87, #800000f2);
}

.formstye {
  text-align: center;
  margin: 0px auto;
  width: 100%;
}

.mt-m-25 {
  margin-top: -25px;
}

.border-radius-zero {
  border-radius: 30px !important;
}

.m-l-10 {
  margin-left: 10px;
}

.login-card {
  background-color: #ffffff5e !important;
  border: 1px solid rgba(0, 0, 0, 0.02) !important;
  border-radius: 1rem;
}

.loginbtn {
  background-color: #bc0000d1 !important;
  border-color: #bc0000d1 !important;
  font-size: 15px !important;
  height: 40px;
}

.fw-600 {
  font-weight: 600;
}

.text-right {
  text-align: left;
}

.fs-label {
  font-size: 13px;
}

.fs-input {
  font-size: 14px !important;
}
