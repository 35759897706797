/* recent activiy */
.card-recent {
    box-shadow: 0 9px 20px rgba(46, 35, 94, .07);
    letter-spacing: .5px;
    margin-bottom: 30px;
    border: none;
    border-radius: 15px;
}

.img-content-box a {
    color: inherit;
    text-align: left;
    text-decoration: none;
}

.img-content-box a:hover {
    color: inherit;
}

.img-content-box span {
    align-items: left;
    color: #7366ff;
}

.card-recent .card-header {
    background-color: #fff;
    border-bottom: 1px solid #ecf3fa;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 30px;
    position: relative;
}

.header-top {
    align-items: center;
    display: flex;
    gap: 4px;
    justify-content: space-between;
}

.dropdown-recent .dropdown-toggle {
    background-color: rgba(115, 102, 255, .08) !important;
    border: none !important;
    border-radius: 10px;
    color: #7366ff;
    padding: 5px 10px;
    text-align: left;
    width: 100px;
}

.dropdown-menu {
    border: none;
    box-shadow: 0 0 20px rgba(89, 102, 122, .1);
    padding: 0;
}

.card-recent .card-body {
    background-color: transparent;
    padding: 20px;
}

td .img-content-box {
    float: left;
}

img.media {
    border: none;
    display: inline;
    display: initial;
    margin-bottom: 0;
    width: 30px;
}

.text-end {
    text-align: right !important;
}

.font-success {
    color: #54ba4a !important;
}

/* Loaded truck */
.truck-data {
    height: 50vh;
}
.recent-wrapper{
    margin-top: 10px;
}

.recent-wrapper .order-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.recent-wrapper .order-content li {
    align-items: flex-start;
    display: flex;
    gap: 8px;
}

.recent-circle {
    border-radius: 100%;
    display: inline-block;
    height: 10px;
    margin-top: 5px;
    min-width: 10px;
}
.unload-truck{
    background: rgba(0,227,150,1);
}

