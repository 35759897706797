#content-wrapper {
  background-color: #f8f9fc;
  width: 100%;
  overflow-x: hidden;
}

#content {
  flex: 1 0 auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.content {
  display: flex;
  justify-content: space-between;
  max-width: 400px;
  margin: 0 auto;
  color: #f8f9fc;
  padding: 10px 0;
}

.content i {
  opacity: 0.4;
}

.icon {
  font-size: 40px;
}

.titleValue h5 {
  font-weight: 800;
  font-size: 2rem;
}

.titleValue {
  float: left;
}

.card-button {
  padding: 5px;
  border-top: 1px solid none;
  background-color: currentColor;
  opacity: 0.4;
}
.card-button a {
  text-decoration: none;
  color: #f8f9fc;
}
.card-button a:hover {
  color: #f8f9fc;
}
.card-link {
  color: #ffff;
  text-decoration: none;
}
.card-button_loads {
  padding: 5px;
}
.card-button_loads a {
  text-decoration: none;
}
.table-responsive {
  height: 330px;
  overflow: hidden;
}
