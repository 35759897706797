.sidebar {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  width: 15.5rem !important;
  background-color: #dadada;
  position: relative;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.04) !important;
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#content-wrapper {
  margin-left: 15.5rem !important;
}

.logo {
  align-items: center;
  justify-content: center;
  padding: 1.4rem;
}

.Menu {
  margin-top: 1.5rem;
  margin-bottom: 0.2rem;
}

/* sidebaritems */

.menuitem {
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  height: 2rem;
  margin: 1rem;
  border-radius: 25px;
  background: lightgray;
  width: 80%;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  /* margin-bottom: 30px; */
  /* margin-top: 5px; */
}

.active {
  background-color: #0e0e0e;
}

.active a:hover {
  color: #e4e8e6;
}

.active a {
  color: #e4e8e6;
}

.active .popbtn {
  color: #e4e8e6;
}

.bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #0e0e0e;
  text-decoration: none;
  margin-left: 0.5rem;
  width: 100%;
}

.bar:hover {
  color: inherit;
}

.menuItemtitle {
  text-align: left;
  width: 100%;
  display: block;
  padding-left: 9px;
}

.last-child {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.last-child .menuitem {
  background: #a8a4a4;
  color: #2c2c2c;
}

.last-child .menuitem a {
  color: #2c2c2c;
}

.accordion-button {
  background: #dadada !important;
  border: none;
  padding: 0px !important;
}

.accordion-item {
  border: 1px solid #dadada !important;
}

.accordion-button:focus {
  border: 1px solid #dadada !important;
  outline: none !important;
  box-shadow: none !important;
}

.accordion .menuitem {
  width: 80%;
  padding-left: 15px;
  border-radius: 25px !important;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.accordion-button::after {
  left: -10px;
  position: relative;
}

.accordion-collapse {
  background: #fff !important;
}

.accordion-body {
  padding: 0px !important;
}

.accordion .menuitem {
  margin-top: 5px;
  margin-bottom: 5px;
}

.accordion-body .active {
  width: 80%;
  margin-top: 15px;
  margin-bottom: 15px;
  background: #000;
  border-radius: 25px !important;
  border-left: none !important;
}

.accordion-body .menuitem {
  border-left: 2px dotted #d7d7d7;
  border-radius: 0px;
  margin-left: 30px;
}

.settingsAccordion .accordion-body .bar {
  margin-left: 0px;
}

.last-child .settingsAccordion .accordion-body .menuitem a {
  color: #e3dfdf;
}

.last-child .settingsAccordion .accordion-body .menuitem a {
  color: #fff;
}

.items {
  height: 200px;
  overflow: hidden;
}

.loadsitems {
  height: 385px;
  overflow: hidden;
}

.sidebar-items {
  height: 450px;
  color: #0e0e0e;
  text-decoration: none;
}

.popbtn {
  cursor: pointer;
  float: right;
  margin-left: 20px;
}
