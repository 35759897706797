.comment-heading {
  display: flex;
  align-items: center;
  height: 55px;
  font-size: 14px;
}

.comment-info {
  color: rgba(0, 0, 0, 0.5);
  margin-left: 10px;
  margin-top: 20px;
}

.comment-author {
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  text-decoration: none;
  margin-left: 5px;
}

.comment-author:hover {
  text-decoration: underline;
}

.replies {
  margin-left: 20px;
}

/* Adjustments for the comment border links */

details.comment .summary {
  position: relative;
  list-style: none;
  cursor: pointer;
}

details.comment .summary::-webkit-details-marker {
  display: none;
}

details.comment:not([open]) .comment-heading {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.comment-heading::after {
  display: inline-block;
  position: absolute;
  right: 5px;
  align-self: center;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.55);
}

.d-none {
  display: none;
}

.comments-title {
  font-size: 30px;
  margin-bottom: 20px;
}

.comment-text {
  font-size: 18px;
}

.driver-message {
  background: #ffe6e6 !important;
}

.manager-message {
  background: #e1e1e1 !important;
}

.driver-fs-st {
  font-weight: 600;
}

.avatar {
  width: 30px;
  height: 30px;
}

.driver-card {
  margin-left: 50%;
}

.active {
  background-color: #0e0e0e;
}

.active a:hover {
  color: #e4e8e6;
}

.active a {
  color: #e4e8e6;
}

.ticket-chat-mine {
  display: flex;
  flex-direction: row-reverse;
  column-gap: 12px;
}
.ticket-chat-other {
  display: flex;
  flex-direction: row;
  column-gap: 12px;
}
.ticket-avatar {
  max-width: 80px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  text-align: center;
}
.ticket-comment {
  margin-top: 8px;
  flex-direction: col;
}
