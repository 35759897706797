.chat {
  width: 100%;
  /* box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.20); */
}

.header-chat {
  background-color: #fff;
  height: 90px;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.1);
  /* display: flex; */
  align-items: center;
}

.chat .header-chat .icon {
  /* margin-left: 30px;
    color: #515151;
    font-size: 14pt; */
  display: flex;
  align-items: center;
  padding: 1.3rem;
}

.chat .header-chat .name {
  margin: 0 0 0 20px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #515151;
  overflow: hidden;
}

.name b {
  display: flex;
  text-align: left;
}

.name small {
  display: flex;
  text-align: left;
}

.chat-list {
  text-align: right;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.chat-list-item {
  margin-right: 0.5rem;
  display: inline-block;
  padding-top: 6%;
}

.chat .header-chat .right {
  position: absolute;
  right: 40px;
}

.messagebox {
  max-height: 100%;
  height: calc(100vh - 270px);
  position: relative;
  overflow: scroll;
}

.otherMessagebox {
  max-height: 100%;
  height: calc(100vh - 346px);
  position: relative;
  overflow: scroll;
}

.messagebox-wrapper {
  height: inherit;
  max-height: inherit;
  max-width: inherit;
  width: inherit;
}

.chat-messages {
  margin: 0;
  padding: 0;
  /* position: absolute; */
  top: 0;
}

.chat-messages .photo {
  display: block;
  width: 45px;
  height: 45px;
  background: #e6e7ed;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.chat-online {
  position: relative;
  top: -10px;
  left: 35px;
  width: 13px;
  height: 13px;
  background-color: #8bc34a;
  border-radius: 13px;
  border: 3px solid #fafafa;
}

.chat-offline {
  color: #e4606d;
}

.chat-messages {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.chat-message-left {
  display: flex;
  align-self: flex-start;
  margin-bottom: 24px;
  position: relative;
  background: #e6e6e6 !important;
  padding: 15px;
  border-radius: 12px;
}

.chat-message-right {
  display: flex;
  flex-direction: row-reverse;
  align-self: flex-end;
  background-color: #e3effd !important;
  padding: 15px;
  border-radius: 12px;
}

.chat-right {
  display: flex;
  justify-content: flex-end;
}

.chat-avatar {
  margin: 0 16px 0 0;
}

.chat-avatar img {
  border-radius: 50%;
  height: 50px;
  width: 50px;
}

.chat_name {
  font-size: 14px;
  font-weight: 500;
  display: flex;
}

.chat_box {
  background-color: #f6f6f6;
}

.chat-test-wrapper {
  display: flex;
}

.chat-test-wrapper-content {
  border-radius: 8px 8px 8px 0;
  position: relative;
}

.chat-test-wrapper-content {
  margin-top: 0;
}
.tickicon {
  color: blue;
}

.chat-time {
  font-size: 15px;
  margin-top: 4px;
  text-align: right;
  color: rgba(60, 51, 176, 0.5);
}
.Chat-DM {
  margin-left: 40px;
}

.chat-read {
  color: #9b9b9b;
  background-color: transparent !important;
  font-size: 14px;
  position: relative;
  align-self: flex-end;
  margin-bottom: 24px;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.icon_bubble .msg {
  border-radius: 50%;
  box-shadow: none;
  color: #7a7f9a;
  color: var(--bs-secondary-color);
  font-size: 20px;
  height: 30px;
  line-height: 40px;
  padding: 0;
  width: 40px;
}

.msg_name {
  display: block;
  left: 0;
  color: #bbb;
  font-size: 12px;
  margin-right: 50%;
  margin-bottom: 10px;
}

.footer-chat {
  background-color: transparent;
  border-top: 2px solid #eee;
  margin-bottom: 0;
  padding: 1.5rem !important;
}

.chat .footer-chat .send {
  color: #fff;
  background: #4f6ebd;
  cursor: pointer;
}

.chat .footer-chat .name {
  margin: 0 0 0 20px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-size: 13pt;
  color: #515151;
}

.chat .footer-chat .right {
  position: absolute;
  right: 40px;
}

.write-message {
  border: none !important;
  width: 75%;
  height: 50px;
  margin-left: 20px;
  padding: 10px;
}

/* list Page */
.discussions {
  width: 100%;
  height: 100%;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
  overflow: scroll;
  scrollbar-width: 0px;
  display: inline-block;
}

.discussions .discussion {
  width: 100%;
  height: 90px;
  background-color: #fafafa;
  border-bottom: solid 1px #e0e0e0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.discussions .message-active {
  width: 98.5%;
  height: 90px;
  background-color: #a5bce7;
  border-bottom: solid 1px #e0e0e0;
}

.discussion .photo img {
  margin-left: 20px;

  border-radius: 50%;
}

.online {
  position: relative;
  top: 43px;
  left: 48px;
  width: 13px;
  height: 13px;
  background-color: #8bc34a;
  border-radius: 13px;
  border: 3px solid #8bc34a;
}

.desc-contact {
  height: 43px;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 15px;
  color: darkgray;
  font-size: 10pt;
}

.desc-contact-active {
  height: 43px;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 15px;
  color: gainsboro;
  font-size: 10pt;
}

.discussions .discussion .name {
  margin: 0 0 0 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 11pt;
  color: #000;
  font-weight: 700;
  margin-left: 0px;
}

.messages-chat .discussions .discussion .message {
  position: relative;
  margin: 6px 0 0 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 9pt;
  color: #515151;
}

.timer {
  margin-left: 15%;
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  padding: 3px 8px;
  color: #bbb;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 15px;
}

.sec_loc_heading {
  font-size: 25px;
  text-align: left;
  padding: 10px;
}

.welcome-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 25%;
}
