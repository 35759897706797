.form-wrapper {
  margin: 20px;
  text-align: left;
}
.form_image {
  margin-top: 20px;
  width: 150px;
  height: 150px;
  border-radius: 75px;
  border: 2px solid #dadada;
  object-fit: cover;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

/* #customers tr:nth-child(even){background-color: #f2f2f2;} */

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #a8a4a4;
  color: white;
}
.textEsist {
  background-color: #a8a4a4;
  padding: 10px;
  margin-top: 15px;
  color: #fff;
}
