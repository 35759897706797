.topbar-link {
  height: 4.375rem;
  /* display: flex;
    justify-content: space-around;
    align-items: center; */
  /* width: 600px; */
}

/* .navbar{
    position: fixed;
    top: 0;
    width: 100%;
} */
.topbar {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.04) !important;
}

.nav-bar {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1em;
}

.nav-links {
  width: 100%;
  height: 200px;
  background: #cee;
}

.nav-item .nav-link {
  height: 4.375rem;
  display: flex;
  align-items: center;
  margin-right: 2.5rem;
}

.nav-item a {
  text-decoration: none;
  color: inherit;
}

.nav-link .img-profile {
  height: 2rem;
  width: 2rem;
  border: 2px solid #dadada;
  object-fit: cover;
}

.nav-link .rounded-circle {
  border-radius: 50%;
}

.topbar-item {
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  height: 2rem;
  margin: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background: lightgray;
  background-color: lightgray;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 5px;
  text-decoration: none;
  padding: 9px;
}

.top-bar {
  color: #0e0e0e;
  text-decoration: none;
}

.topbar {
  position: fixed !important;
  z-index: 999;
  top: 0;
  left: 0;
  width: calc(100vw - 15.5rem);
  margin-left: 15.5rem;
  display: block;
}

.container-fluid-top {
  margin-top: 150px !important;
}

#content .container-fluid {
  margin-top: 90px !important;
  height: calc(100vh - 90px);
}

.topbar-divider {
  width: 0;
  border-right: 1px solid #e3e6f0;
  height: calc(4.375rem - 2rem);
  margin: auto 1rem;
}

.closebtn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 10px;
  text-align: center;
}

.active {
  background-color: #0e0e0e;
}

.active a:hover {
  color: #e4e8e6;
}

.active a {
  color: #e4e8e6;
}

.active .closebtn {
  color: #fff;
}

.overlay {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1em;
}

.Linkers {
  width: 800px;
  height: 200px;
}

.dropdown-toggle::after {
  display: none !important;
}

.pulse-css {
  position: relative;
  top: 0;
  right: -13px;
  height: 15px;
  width: 15px;
  background: #21b7b7;
  color: #fff;
  border-radius: 100%;
  font-size: 10px;
  font-weight: bold;
  line-height: 15px;
}

.notification-btn {
  background-color: inherit;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.dropdown-noti {
  position: absolute;
  top: 100%;
  z-index: 1000;
  float: inherit;
  min-width: 160px;
  padding: 5px 0;
  margin: 4px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  right: 15%;
  max-width: 300px;
  max-height: 400px;
  overflow-y: auto;
}

.dropdown-noti ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown-noti ul li {
  padding: 5px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.dropdown-noti ul li:hover {
  background-color: #ddd;
}

.dropdown-noti a {
  color: inherit;
  text-decoration: none;
}

.timeline-panel {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.media {
  width: 50px;
  height: 50px;
  background: #eee;
  border-radius: 12px;
  overflow: hidden;
  font-size: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  align-self: start;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.d-block {
  display: block !important;
}

.media img {
  border-radius: 0.1875rem;
}

.media-body {
  flex: 1;
  padding: 5px;
}

h6,
.h6 {
  font-size: 0.938rem;
}

.notification-time {
  font-size: 0.8rem;
  text-align: right;
  color: #808080;
}
